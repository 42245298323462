import * as React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { styled, ThemeProvider } from '@mui/material/styles';

const Arrows = '/loader/arrows.png';
const Circle = '/loader/circle.png';
const EyeFrame = '/loader/eyeFrame.png';
import Stack from '@mui/material/Stack';
import { EDTypography, grey, edWebkitTheme } from '@trailblazer-game/ed-webkit';
import { theme } from '../Theme/Theme';

export interface ISPPageLoaderProps {
    className?: string;
    in: boolean;
    loadingText?: string;
}

// Component
function SPPageLoader(props: ISPPageLoaderProps) {
    return (
        <ThemeProvider theme={theme}>
            <Fade in={props.in} timeout={100} unmountOnExit>
                <Box className={props.className}>
                    {props.in && (
                        <Stack alignItems={'center'} justifyContent={'center'}>
                            <Box className={'loader'}>
                                <Box className={'loader-part'} id={'arrows'}></Box>
                                <Box className={'loader-part'} id={'eyeFrame'}></Box>
                                <Box className={'loader-part'} id={'circle'}></Box>
                            </Box>
                            <EDTypography
                                variant={'body1'}
                                sx={{
                                    color: grey('50'),
                                    textTransform: 'uppercase'
                                }}>
                                {props.loadingText}
                            </EDTypography>
                        </Stack>
                    )}
                </Box>
            </Fade>
        </ThemeProvider>
    );
}

// Default style!
const SPPageLoaderStyled = styled(SPPageLoader)<ISPPageLoaderProps>((props) => ({
    zIndex: 9000,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transformOrigin: '-50% -50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: props.in ? 'wait' : 'default',
    backgroundColor: 'rgba(0,0,0,.75)',
    backdropFilter: 'grayscale(0.5) blur(20px)',
    width: '100%',
    height: '100vh',
    margin: '0 !important',
    maxWidth: '1920px',
    '.loader': {
        width: '256px',
        height: '256px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '.loader-part': {
            position: 'absolute'
        },
        '#arrows': {
            background: `center / contain no-repeat url(${Arrows})`,
            width: '172px',
            height: '172px',
            animation: 'rotate-anticlockwise 4s ease-in-out infinite both'
        },
        '#eyeFrame': {
            background: `center / contain no-repeat url(${EyeFrame})`,
            width: '256px',
            height: '256px',
            animation: 'blink 4s ease-in-out infinite both'
        },
       
        '#circle': {
            background: `center / contain no-repeat url(${Circle})`,
            width: '112px',
            height: '112px',
            animation: 'rotate-anticlockwise 4s ease-in-out infinite both'
        },
        h6: {
            position: 'absolute',
            bottom: `-${edWebkitTheme.spacing(4)}`
        }
    },
    '@keyframes rotate-clockwise': {
        '0%': {
            transform: 'rotate(0)'
        },
        '100%': {
            transform: 'rotate(360deg)'
        }
    },
    '@keyframes rotate-anticlockwise': {
        '0%': {
            transform: 'rotate(0)'
        },
        '100%': {
            transform: 'rotate(-360deg)'
        }
    },
    '@keyframes blink': {
        '0%': {
            opacity: '0.8'
        },
        '50%': {
            opacity: '1'
        },
        '100%': {
            opacity: '0.8'
        }
    }
}));

SPPageLoader.defaultProps = {
    loadingText: 'Loading...'
};

// Export
export default SPPageLoaderStyled;

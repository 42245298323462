import * as React from "react";
import dynamic from "next/dynamic";
import PageLayout from "../components/Shared/PageLayout";
import { Box, Stack } from "@mui/material";
import SPPageLoader from "../components/SPComponents/SPPageLoader";
//import 'prismjs/themes/prism.css';
//import 'prismjs/components/prism-javascript';

const Home = dynamic(() => import("../components/Pages/Home/Public/Home"), {
  suspense: false,
  ssr: false,
  loading: () => <SPPageLoader in={true} />,
})
export default function HomePage() { 
  return <Home />
}

HomePage.metaData = {
  title: 'Superplay - Home',
  description: "Superplay!"
}